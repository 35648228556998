import { useQuery } from "@tanstack/react-query";
import { boardService } from "../service/boardService";
import { BoardQueryKey } from "../const/QueryKey";

export const useBoardDetail = (board_id, options = {}) => {
  return useQuery({
    queryKey: [BoardQueryKey.BOARD_DETAIL, board_id],
    queryFn: () => boardService.getBoardDetail(board_id),
    ...options,
    select: (data) => {
      return data.data.data;
    },
  });
};
