import React from "react";
import { Box, Typography, Divider } from "@mui/material";
import imgPlatform from "../img/img_platform_00.svg";
import imgPlatform1 from "../img/img_platform_01.png";
import imgPlatform2 from "../img/img_platform_02.png";
import imgPlatform3 from "../img/img_platform_03.png";
import imgPlatform4 from "../img/img_platform_04.png";
import imgPlatform5 from "../img/img_platform_05.png";

function Platform() {
  return (
    <Box className="contents business">
      <Box className="contents-header">
        <Box className="inner">
          <Typography className="category" variant="caption">
            Business
          </Typography>
          <Typography className="tit" variant="h3">
            Platform
          </Typography>
        </Box>
      </Box>
      <Box className="contents-container platform">
        <Box className="inner">
          <Typography className="tit" variant="h3">
            CADD(Computer-Aided Drug Design) Platform
          </Typography>
          <div className="article-wrap col">
            <span className="article-img">
              <img className="img" src={imgPlatform} alt="" />
            </span>
            <Typography className="desc" variant="body1">
              특정 질병과 관련된 타깃 단백질에 대한 혁신신약 발굴을 하는 과정은
              유효물질 발굴, 선도물질 발굴, 그리고 선도물질 최적화로 나눌 수
              있다. <br />
              이 과정에서 수많은 화합물들의 다양한 약물가능성 예를 들어 타깃
              단백질에 대한 높은 결합력, 낮은 세포독성, 높은 용해도 또는 친유성
              등에 대한 정확한 평가가 필요하다. <br />
              특히, 결합력과 독성에 대한 잘못된 평가는 임상 1, 2상에서 약물개발
              실패의 각각 약57%와 30%의 요인이 되기 때문에 이에 대한 정확한
              평가의 중요성은 아무리 강조해도 지나치지 않는다. 이 평가의
              정확성을 위해 에임블은 자체 구축한 데이터베이스와 고속화된 도킹
              솔루션, 결합력 예측 솔루션, 그리고 독성 예측 솔루션을 포함한
              딥러닝 기반의 고도화된 플랫폼을 개발하고 있다. 이와 동시에
              에임블의 딥러닝 모델의 정확도를 개선하기 위해서 계속해서
              양자계산과 분자동역학 계산으로부터 생성한 양질의 데이터를 축적해
              나아가고 있다.
            </Typography>
          </div>
          <Divider />
          <div className="article-wrap">
            <span className="article-img">
              <img className="img" src={imgPlatform1} alt="" />
            </span>
            <Box className="article">
              <Typography className="article-tit" variant="h4">
                AI기반 타겟 단백질 발굴 솔루션 개발
              </Typography>
              <dl>
                <dt>네트워크 기반 엔지니어링</dt>
                <dd>
                  세포 내에서 일어난 특정 단백질의 변형이나 돌연변이는 특정
                  질병과 연관 되어있으며 여러 데이터베이스를 활용하여 구성한
                  상호작용하는 단백질의 네트워크를 분석
                </dd>
                <dd>
                  네트워크의 노드 분류, 새로운 연결 예측이 가능한 AI 모델을
                  활용하여 기존에 알려지지 않은 새로운 질병 연관 단백질을 발견
                </dd>
                <dd>
                  이를 통해 신규 단백질 타겟을 발굴하여 chemical 또는 peptide
                  신약개발에 활용
                </dd>
              </dl>
            </Box>
          </div>
          <Divider />
          <div className="article-wrap">
            <span className="article-img">
              <img className="img" src={imgPlatform2} alt="" />
            </span>
            <Box className="article">
              <Typography className="article-tit" variant="h4">
                대량의 도킹 작업을 효율적으로 수행하기 위한 고속 병렬 스크리닝
                솔루션 개발
              </Typography>
              <dl>
                <dt>
                  초기 선별 대상이 되는 다량의 화합물 및 타겟 단백질
                  데이터베이스 관리
                </dt>
                <dd>단백질 및 화합물의 원본 데이터를 선별한 데이터</dd>
                <dd>그 데이터를 도킹에 적합하게 전처리한 데이터</dd>
                <dd>도킹 후 구조 정보 등이 자동으로 저장</dd>
                <dd>자동화 및 데이터베이스화를 통해 향후 시스템 확장에 용이</dd>
              </dl>
            </Box>
          </div>
          <Divider />
          <div className="article-wrap">
            <span className="article-img">
              <img className="img" src={imgPlatform3} alt="" />
            </span>
            <Box className="article">
              <Typography className="article-tit" variant="h4">
                AI기반 단백질-화합물 결합력 예측 솔루션 개발
              </Typography>
              <dl>
                <dt>구조 기반 특징 엔지니어링</dt>
                <dd>
                  화합물-단백질 결합 부위 원자들의 화학적 환경을 묘사하는 최적의
                  수치 표현법을 구현
                </dd>
                <dd>
                  PDBBind database를 학습데이터로 사용하여 인공신경망(Artificial
                  Neural Net, ANN)이나 의사결정 나무(Decision Tree, DT) 등의
                  기계학습(Machine Learning, ML) 구축
                </dd>
                <dt>결합력 예측 인공지능 모델의 성능</dt>
                <dd>
                  PDBBind coreset(285개의 단백질-화합물 복합체)에 대한 결합력
                  예측 → 우수한 성능 계수[Pearson 상관 계수: 0.87, Spearman
                  계수: 0.86, 제곱 평균 오차(MSE): 2.63]를 결과로 얻었으며 이는
                  현재 AI를 이용해 가장 높은 Pearson 상관계수(0.866)를 논문에
                  보고한 멕시코/스페인 연구팀의 결과와 동등.
                </dd>
              </dl>
            </Box>
          </div>
          <Divider />
          <div className="article-wrap">
            <span className="article-img">
              <img className="img" src={imgPlatform4} alt="" />
            </span>
            <Box className="article">
              <Typography className="article-tit" variant="h4">
                AI기반 화합물 독성 예측 솔루션 개발
              </Typography>
              <dl>
                <dt>데이터베이스 구축 및 구조 기반 특징 엔지니어링</dt>
                <dd>
                  독성 카테고리별 공개 데이터베이스(EPA, Tox21, PubChem 등)와
                  개별 논문에서 사용된 데이터를 통합하여 자체
                  데이터베이스(in-house database) 구축
                </dd>
                <dd>
                  화합물의 토폴로지 구조를 표현하는 핑거프린트(fingerprint)와
                  다양한 물리화학적 특징(feature)을 추출하고 유전 알고리즘을
                  이용하여 특징을 선별, 최적의 조합을 찾아내 성능 극대화함.
                </dd>
                <dd>
                  다양한 타입의 기계학습 모델을 테스트하고 최적의 성능을 가진
                  모델 선택 및 조합
                </dd>
                <dt>독성 예측 AI 모델의 성능</dt>
                <dd>
                  에임블의 독성 예측 소프트웨어의 우수한 성능 [Area under
                  curve(AUC): 0.89, Balanced Accuracy: 0.90, 그림 참고]{" "}
                </dd>
              </dl>
            </Box>
          </div>
          <Divider />
          <div className="article-wrap">
            <span className="article-img">
              <img className="img" src={imgPlatform5} alt="" />
            </span>
            <Box className="article">
              <Typography className="article-tit" variant="h4">
                CADD Platform 성능 검증
              </Typography>
              <dl>
                <dt></dt>
                <dd>
                  Autodock_vina 대비 Aimble-Platform을 통해 얻은 예측값이
                  실험값에 훨씬 우수한 상관관계를 보임
                </dd>
              </dl>
            </Box>
          </div>
        </Box>
      </Box>
    </Box>
  );
}
export default Platform;
