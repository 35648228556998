import React from "react";
import { Paper, Box, Typography, Divider, Stack, Button } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Viewer } from "@toast-ui/react-editor";
import { useBoardDetail } from "../hook/useBoardDetail";
import { useSearchParams } from "react-router-dom";
import "@toast-ui/editor/dist/toastui-editor-viewer.css";

function PressReleaseDetail() {
  const [searchParams] = useSearchParams();
  const board_id = searchParams.get("board_id");
  const enabled = !!board_id;

  const { data: result, isSuccess } = useBoardDetail(board_id, {
    enabled: enabled,
  });

  return (
    <Box className="contents news-detail">
      <Box className="contents-header">
        <Box className="inner">
          <Typography className="category" variant="caption">
            Newsroom
          </Typography>
          <Typography className="tit" variant="h3">
            PressRelease
          </Typography>
        </Box>
      </Box>
      {isSuccess && (
        <Box className="contents-container">
          <Box className="inner">
            <Paper className="view" elevation={0}>
              <Box className="view-header">
                <Typography className="tit" variant="h5">
                  <strong>{result?.title}</strong>
                </Typography>
                <Stack
                  direction="row"
                  divider={<Divider orientation="vertical" />}
                  spacing={2}
                >
                  <Typography className="desc" variant="body2">
                    <strong>기사일</strong>
                    <span>{result?.display_dt}</span>
                  </Typography>
                  <Typography className="desc" variant="body2">
                    <strong>조회수</strong>
                    <span>{result?.view_cnt}</span>
                  </Typography>
                </Stack>
              </Box>
              <Box className="view-content">
                <Viewer initialValue={result?.content} />
              </Box>
              <Stack
                className="view-controls"
                direction="row"
                justifyContent={"space-between"}
              >
                <Button
                  variant="text"
                  color="inherit"
                  startIcon={<ArrowBackIosIcon />}
                  disabled={result?.prev === 0 ? true : false}
                  href={"/PressReleaseDetail?board_id=" + result?.prev}
                >
                  Prev
                </Button>
                <Button variant="outlined" color="inherit" href="/PressRelease">
                  목록보기
                </Button>
                <Button
                  variant="text"
                  color="inherit"
                  endIcon={<ArrowForwardIosIcon />}
                  disabled={result?.next === 0 ? true : false}
                  href={"/PressReleaseDetail?board_id=" + result?.next}
                >
                  Next
                </Button>
              </Stack>
            </Paper>
          </Box>
        </Box>
      )}
    </Box>
  );
}
export default PressReleaseDetail;
