export const errorHandler = (error, query) => {
  let message;
  const status = error.response.status;

  if (status >= 400 && status < 500) {
    message = error.response.statusText;
  } else if (status >= 500 && status < 600) {
    message = error.response.data.message;
  } else {
    if (error.code === "ERR_NETWORK") {
      message = "네트워크 연결이 종료되었습니다.";
    }
  }

  if (message) {
    console.log(error, query);
  }
};
