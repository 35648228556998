import React, { useState } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemButton,
  Pagination,
  Stack,
  Divider,
} from "@mui/material";
import { useBoardList } from "../hook/useBoardList";

function Notice() {
  const [pageNum, setPageNum] = useState(1);
  const pageSize = 5;

  const { data: boardList } = useBoardList({
    page_num: pageNum,
    page_size: pageSize,
    type: "notice",
  });

  let last_page = boardList
    ? boardList?.total % pageSize === 0
      ? parseInt(boardList.total / pageSize)
      : parseInt(boardList.total / pageSize) + 1
    : 0;

  const handlePage = (event, value) => {
    setPageNum(value);
  };

  return (
    <Box className="contents notice">
      <Box className="contents-header">
        <Box className="inner">
          <Typography className="category" variant="caption">
            Newsroom
          </Typography>
          <Typography className="tit" variant="h3">
            Notice
          </Typography>
        </Box>
      </Box>
      <Box className="contents-container">
        <Box className="inner">
          {boardList && (
            <List>
              {boardList.list.map((row) => {
                return (
                  <ListItem disablePadding key={row.board_id}>
                    <ListItemButton
                      href={"/NoticeDetail?board_id=" + row.board_id}
                    >
                      <p className="date">
                        <strong>{row.ins_dt.substring(8, 10)}</strong>
                        <span>
                          {row.ins_dt.substring(0, 4)}.
                          {row.ins_dt.substring(5, 7)}
                        </span>
                      </p>
                      <Box className="txt-wrap">
                        <Typography className="tit" variant="h5">
                          <strong>{row.title}</strong>
                        </Typography>
                        <Stack
                          direction="row"
                          divider={<Divider orientation="vertical" />}
                          spacing={2}
                        >
                          <Typography className="desc" variant="body2">
                            {row.content.replace(/(<([^>]+)>)/gi, "")}
                          </Typography>
                          {/* <Typography className="desc" variant="body2">
                            작성자 : {row.writer}
                          </Typography>
                          <Typography className="desc" variant="body2">
                            조회수 : {row.view_cnt}
                          </Typography> */}
                        </Stack>
                      </Box>
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          )}
          {boardList && boardList?.total > 0 && (
            <Pagination
              count={last_page}
              size="large"
              page={pageNum}
              onChange={handlePage}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}
export default Notice;
