import React from "react";
import { Box, Typography } from "@mui/material";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";

function History() {
  return (
    <Box className="contents company">
      <Box className="contents-header">
        <Box className="inner">
          <Typography className="category" variant="caption">
            Company
          </Typography>
          <Typography className="tit" variant="h3">
            연혁
          </Typography>
        </Box>
      </Box>
      <Box className="contents-container">
        <Box className="inner">
          <Timeline>
            <TimelineItem>
              <TimelineOppositeContent>
                <Typography className="year">2023</Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography className="date">2023.05</Typography>
                <Typography className="txt">
                  창업중심대학 초기창업과제 선정
                </Typography>
                <Typography className="date">2023.04</Typography>
                <Typography className="txt">
                  대원제약과 위탁연구 계약
                </Typography>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineOppositeContent>
                <Typography className="year">2022</Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography className="date">2022.05</Typography>
                <Typography className="txt">
                  Pre-A 투자 유치 (슈미트, 동훈 인베스트먼트)
                </Typography>
                <Typography className="date">2022.01</Typography>
                <Typography className="txt">특허 출원</Typography>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineOppositeContent>
                <Typography className="year">2021</Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography className="date">2021.08</Typography>
                <Typography className="txt">팁스 선정</Typography>
                <Typography className="date">2021.06</Typography>
                <Typography className="txt">
                  팁스연계 Seed투자 유치 (슈미트)
                </Typography>
                <Typography className="date">2021.02</Typography>
                <Typography className="txt">기업부설 연구소 인정</Typography>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineOppositeContent>
                <Typography className="year">2020</Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography className="date">2020.12</Typography>
                <Typography className="txt">벤처인증</Typography>
                <Typography className="date">2020.11</Typography>
                <Typography className="txt">
                  Seed투자 유치 (뉴패러다임 인베스트먼트)
                </Typography>
                <Typography className="date">2020.05</Typography>
                <Typography className="txt">회사설립</Typography>
              </TimelineContent>
            </TimelineItem>
          </Timeline>
        </Box>
      </Box>
    </Box>
  );
}
export default History;
