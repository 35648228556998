import * as React from "react";
import { Box, Typography, Stack } from "@mui/material";
import team1 from "../img/team_01.jpg";
import team2 from "../img/team_02.jpg";

function Team() {
  return (
    <Box className="contents company">
      <Box className="contents-header">
        <Box className="inner">
          <Typography className="category" variant="caption">
            Company
          </Typography>
          <Typography className="tit" variant="h3">
            구성원
          </Typography>
        </Box>
      </Box>
      <Box className="contents-container">
        <Box className="inner">
          <Box className="team">
            <Box className="team-info">
              <Stack direction="row" spacing={2} alignItems="flex-end">
                <div className="team-info-img">
                  <img src={team1} alt="" />
                </div>
                <Typography className="team-info-name" variant="h4">
                  <span>CEO</span> 김현진
                </Typography>
              </Stack>
              <ul className="team-info-list">
                <li>
                  <strong>2005 서울대학교 물리학과 박사졸업</strong>
                </li>
                <li>
                  <strong>
                    2002~2015 Carnegie Mellon University 화학과
                    교환학생/연구원/방문교수
                  </strong>
                  분자동역학을 활용한 단백질 구조 분석 및 단백질-약물 상호작용
                  연구
                  <br />
                  분자동역학을 활용한 U. Pitt 의대와의 담석 결정 구조 형성
                  모델링 및 이온들의 영향에 대한 공동연구
                  <br />
                  분자동역학을 활용한 ionic Liquid속에서의 Biomass의 구조변화
                  연구
                </li>
                <li>
                  <strong>2016~2018 고등과학원 계산과학부 연구원</strong>
                  분자동역학과 머신러닝을 활용한 단백질 구조의 동역학적 변화
                  연구
                </li>
                <li>
                  <strong>
                    2019~2022 서울대학교 이론물리연구소 선임연구원
                  </strong>
                  한국연구재단중견연구과제수행: 머신러닝과 분자동역학을 활용한
                  단백질-화합물 결합력 예측
                </li>
              </ul>
            </Box>
            <Box className="team-info">
              <Stack direction="row" spacing={2} alignItems="flex-end">
                <div className="team-info-img">
                  <img src={team2} alt="" />
                </div>
                <Typography className="team-info-name" variant="h4">
                  <span>
                    CSO / <br />
                    연구소장
                  </span>{" "}
                  김성진
                </Typography>
              </Stack>
              <ul className="team-info-list">
                <li>
                  <strong>1996 한양대학교 유기합성 석사졸업</strong>
                </li>
                <li>
                  <strong>1996~2019 ㈜C&C 신약연구소 책임연구원</strong>
                  1개의 자사 과제와 13개의 일본 Chugai 협력 과제 수행
                  <br />
                  3개 과제 라이센스 아웃
                  <br />
                  1개 과제 임상 진입
                  <br />
                  (특허: WO/2007/13899 : HSP90 INHIBITOR : inventor)
                  <br />
                  (논문: Bioorg Med Chem Lett. 2011 Oct 1;21(19):5778-83;
                  <br /> Bioorg Med Chem. 2014 Jan 15;22(2):892-905)
                  <br />
                  2개 과제 전임상 후보 물질 개발
                  <br />
                  다수의 대조약물들의 전합성 과제 수행
                </li>
                <li>
                  <strong>2019 ㈜크리스탈지노믹스 수석연구원</strong>
                  Protac 기술 및 kinase targets
                </li>
                <li>
                  <strong>2020~2022 ㈜이노큐어테라퓨틱 이사</strong>
                  신규 CRBN E3 ligands 및 PROTAC 특허 출원중
                  <br />
                  신규 scaffold kinase inhibitor 특허 출원
                </li>
              </ul>
            </Box>
          </Box>
          <Box className="team">
            <dl>
              <dt>R&#38;D CADD</dt>
              <dd>김현진</dd>
              <dd>권선화</dd>
              <dd>강민경</dd>
              <dd>윤이나</dd>
              <dd>백지원</dd>
            </dl>
            <dl>
              <dt>R&#38;D 합성</dt>
              <dd>김성진</dd>
              <dd>나용호</dd>
              <dd>강문구</dd>
            </dl>
            <dl>
              <dt>IT</dt>
              <dd>신종열</dd>
            </dl>
            <dl>
              <dt>MANAGEMENT</dt>
              <dd>성현승</dd>
            </dl>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
export default Team;
