import { useQuery } from "@tanstack/react-query";
import { boardService } from "../service/boardService";
import { BoardQueryKey } from "../const/QueryKey";

export const useBoardList = (params, options = {}) => {
  return useQuery({
    queryKey: [BoardQueryKey.BOARD_LIST, params],
    queryFn: () => boardService.getBoardList(params),
    ...options,
    keepPreviousData: true,
    select: (data) => {
      return data.data.data;
    },
  });
};
