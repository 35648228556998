import React from "react";
import { Box, Typography } from "@mui/material";

function AboutUs() {
  return (
    <Box className="contents company">
      <Box className="contents-header">
        <Box className="inner">
          <Typography className="category" variant="caption">
            Company
          </Typography>
          <Typography className="tit" variant="h3">
            회사소개
          </Typography>
        </Box>
      </Box>
      <Box className="contents-container">
        <Box className="inner">
          <Typography className="sub-tit" variant="h4">
            <span>AIM</span>&nbsp;at a&nbsp;
            <span>B</span>etter&nbsp;
            <span>L</span>ife&nbsp;
            <span>E</span>xtension
          </Typography>
          <Typography className="tit" variant="h3">
            더 나은 생명 연장을 목표로
          </Typography>
          <Typography className="desc" variant="body1">
            <strong>
              사람은 누구나 태어나서 늙고 병들어 죽는 유한한 생명체입니다.
              <br />
              에임블은 사람이 수명 전주기에 걸쳐 건강하고 활동적인 삶을 오래도록
              영위할 수 있도록 돕는 혁신적인 약물 개발에 집중하고 있습니다.
            </strong>
            <br />
            <br />
            <span>
              에임블은 인공지능(AI), 도킹, 분자동역학, 양자계산 등의 첨단 기술을
              활용하여 구조 기반의 인실리코(In-silico) 신약 발굴 플랫폼을
              개발하였습니다. 이를 통해 신속하고 정확한 CADD(Computer-Aided Drug
              Design) 과정을 구현하여 초기 약물 개발의 효율성을 극대화하고
              있습니다. 또한, 합성(Synthesis) 연구소를 설립하고 약물 디자인과
              합성을 One-stop 서비스로 제공하여 제약사나 바이오실험벤처기업 등
              고객사들의 초기 신약개발의 어려움을 극복하는 데 도움을 드리고자
              합니다. 이러한 고객사와의 지속적인 협업을 통해 에임블 플랫폼의
              우수성을 검증 받고 있습니다. 에임블의 연구는 단지 기술 혁신에
              그치지 않고, 인류의 건강한 미래를 위해 다양하고 지속 가능한
              방식으로 이루어지고 있습니다. 우리는 높은 윤리적 기준을 준수하며,
              환경 보호와 지속 가능한 연구 개발을 위해 최선을 다하고 있습니다.
              에임블과 함께 더 나은 미래를 향해 나아가십시오. 혁신적인 기술과
              연구로 인류의 건강한 삶을 연장하는 에임블의 여정에 동참해 주시길
              바랍니다.
            </span>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
export default AboutUs;
