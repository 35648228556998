import api from ".";

export const boardService = {
  getBoardList: async (params) => {
    return await api.basic("get", "/v1/board", params);
  },
  getNewestBoardList: async () => {
    return await api.basic("get", "/v1/board/newest", null);
  },
  getBoardDetail: async (board_id) => {
    return await api.basic("get", "/v1/board/" + board_id, null);
  },
};
