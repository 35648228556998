import axios from "axios";
import qs from "qs";

axios.defaults.paramsSerializer = (params) => {
  return qs.stringify(params, { arrayFormat: "repeat" });
};

export const apiServerinstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

export const oauthinstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

export const authinstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

const api = {
  oauth(method, url, data = null) {
    const req = {
      url: url,
      method: method,
      data: data,
    };

    //get 일때만 params값 추가
    // if (method === 'get' || method === 'delete') req.params = data;
    if (method === "get") {
      req.params = data;
    }

    return oauthinstance(req);
  },
  basic(method, url, data = null) {
    const req = {
      url: url,
      method: method,
      data: data,
    };

    //get 일때만 params값 추가
    if (method === "get" || method === "delete") req.params = data;

    return apiServerinstance(req);
  },
  auth(method, url, data = null) {
    const req = {
      url: url,
      method: method,
      data: data,
    };

    //get 일때만 params값 추가
    if (method === "get" || method === "delete") req.params = data;

    return apiServerinstance(req);
  },
  file(url, formData = null) {
    const req = {
      url: url,
      method: "post",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    return oauthinstance(req);
  },
  down(url, data = null) {
    const req = {
      url: url,
      method: "get",
      params: data,
      responseType: "blob",
    };

    return oauthinstance(req);
  },
};

export default api;
