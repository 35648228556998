import React, { useEffect } from "react";
import {
  Drawer,
  AppBar,
  Toolbar,
  Typography,
  Box,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  IconButton,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
// import LanguageIcon from "@mui/icons-material/Language";
import logo from "../img/logo.svg";
import { useLocation } from "react-router-dom";

export default function Header() {
  const [isHovering, setIsHovering] = React.useState(false);

  let location = useLocation();

  const [open, setOpen] = React.useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  let companyMenuList = ["/AboutUs", "/Team", "/History"];

  let businessMenuList = ["/Platform", "/Service"];

  let newsroomMenuList = ["/Notice", "/PressRelease"];

  let contactUsMenuList = ["/ContactUs", "/Location"];

  // useEffect(() => {
  //   console.log(location.pathname);
  //   Object.keys(menuList).forEach((v) => {
  //     if(menuList[v] == location.pathname) {

  //     }
  //   });
  //   // menuSelected.
  // }, [location.pathname]);

  const DrawerList = (
    <List className={"menu-list " + (isHovering ? "on" : "")}>
      <ListItem className="menu">
        <ListItemButton
          className="menu-levels-1"
          selected={
            companyMenuList.indexOf(location.pathname) > -1 ? true : false
          }
        >
          <ListItemText primary="Company" />
        </ListItemButton>
        <List className="sub-menu">
          <ListItemButton
            className="menu-levels-2"
            href="/AboutUs"
            selected={location.pathname === "/AboutUs" ? true : false}
          >
            <ListItemText primary="회사소개" />
          </ListItemButton>
          <ListItemButton
            className="menu-levels-2"
            href="/Team"
            selected={location.pathname === "/Team" ? true : false}
          >
            <ListItemText primary="구성원" />
          </ListItemButton>
          <ListItemButton
            className="menu-levels-2"
            href="/History"
            selected={location.pathname === "/History" ? true : false}
          >
            <ListItemText primary="연혁" />
          </ListItemButton>
        </List>
      </ListItem>
      <ListItem className="menu">
        <ListItemButton
          className="menu-levels-1"
          selected={
            businessMenuList.indexOf(location.pathname) > -1 ? true : false
          }
        >
          <ListItemText primary="Business" />
        </ListItemButton>
        <List className="sub-menu">
          <ListItemButton
            className="menu-levels-2"
            href="/Platform"
            selected={location.pathname === "/Platform" ? true : false}
          >
            <ListItemText primary="Platform" />
          </ListItemButton>
          <ListItemButton
            className="menu-levels-2"
            href="/Service"
            selected={location.pathname === "/Service" ? true : false}
          >
            <ListItemText primary="Service" />
          </ListItemButton>
        </List>
      </ListItem>
      <ListItem className="menu">
        <ListItemButton
          className="menu-levels-1"
          selected={
            newsroomMenuList.indexOf(location.pathname) > -1 ? true : false
          }
        >
          <ListItemText primary="Newsroom" />
        </ListItemButton>
        <List className="sub-menu">
          <ListItemButton
            className="menu-levels-2"
            href="/Notice"
            selected={location.pathname === "/Notice" ? true : false}
          >
            <ListItemText primary="Notice" />
          </ListItemButton>
          <ListItemButton
            className="menu-levels-2"
            href="/PressRelease"
            selected={location.pathname === "/PressRelease" ? true : false}
          >
            <ListItemText primary="Press Release" />
          </ListItemButton>
        </List>
      </ListItem>
      <ListItem className="menu">
        <ListItemButton
          className="menu-levels-1"
          selected={
            contactUsMenuList.indexOf(location.pathname) > -1 ? true : false
          }
        >
          <ListItemText primary="Contact Us" />
        </ListItemButton>
        <List className="sub-menu">
          <ListItemButton
            className="menu-levels-2"
            href="/ContactUs"
            selected={location.pathname === "/ContactUs" ? true : false}
          >
            <ListItemText primary="문의하기" />
          </ListItemButton>
          <ListItemButton
            className="menu-levels-2"
            href="/Location"
            selected={location.pathname === "/Location" ? true : false}
          >
            <ListItemText primary="오시는 길" />
          </ListItemButton>
        </List>
      </ListItem>
    </List>
  );

  return (
    <AppBar
      component="header"
      className={"header " + (isHovering ? "extend" : "")}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <Toolbar>
        <Typography variant="h1" sx={{ flexGrow: 1 }}>
          <Link underline="none" href="/Home">
            <img className="logo" src={logo} alt="Aimble footer Logo" />
          </Link>
        </Typography>
        <Box component="nav" className="nav">
          {DrawerList}
          {/* <span className='language'>
            <TextField
              select
              color="secondary"
              defaultValue={1}
              size="small"
              sx={{ width: '100px' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LanguageIcon />
                  </InputAdornment>
                ),
              }}
            >
              <MenuItem value={1}>KR</MenuItem>
              <MenuItem value={2}>EN</MenuItem>
            </TextField>
          </span> */}
          <IconButton
            className="btn-menu"
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <Drawer
            className="nav-m"
            open={open}
            onClose={toggleDrawer(false)}
            anchor={"top"}
          >
            {DrawerList}
          </Drawer>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
