import React, { useRef } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import Header from "./components/Header";
import ContainerFluid from "./components/ContainerFluid";
import { Fab } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const theme = createTheme({
  palette: {
    primary: {
      // = _var.scss/$primary
      main: "#ec6786",
      contrastText: "#ffffff",
    },
    primaryLight: {
      // = _var.scss/$primary-light
      main: "#f291a8",
      contrastText: "#ffffff",
    },
    primaryDark: {
      // = _var.scss/$primary-dark
      main: "#E03455",
      contrastText: "#ffffff",
    },
    secondary: {
      // = _var.scss/$secondary
      main: "#7879F1",
      contrastText: "#ffffff",
    },
    secondaryLight: {
      // = _var.scss/$secondary-light
      main: "#8486FF",
      contrastText: "#ffffff",
    },
    secondaryDark: {
      // = _var.scss/$secondary-dark
      main: "#4A45BD",
      contrastText: "#ffffff",
    },
    gray: {
      main: "#E5E6E7",
    },
    error: {
      main: "#ea1917",
    },
    info: {
      main: "#0057FF",
    },
    white: {
      main: "#ffffff",
    },
  },
});

function App() {
  const [DrawerOpen, setDrawerOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setDrawerOpen(!DrawerOpen);
  };

  const ps = useRef();

  function scrollTop() {
    const curr = ps.current;
    if (curr) {
      curr.scrollTop = 0;
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Fab
        className="scroll-top"
        color="inherit"
        aria-label="Scroll Top"
        onClick={scrollTop}
      >
        <KeyboardArrowUpIcon />
      </Fab>
      <PerfectScrollbar
        className="aimble-layout"
        containerRef={(el) => (ps.current = el)}
      >
        <Header onDrawerToggle={handleDrawerToggle} id="back-to-top-anchor" />
        <ContainerFluid open={DrawerOpen} onClose={handleDrawerToggle} />
      </PerfectScrollbar>
    </ThemeProvider>
  );
}
export default App;
