import React from "react";
import { Box, Typography, Card, CardContent } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

function ContactUs() {
  return (
    <Box className="contents contact-us">
      <Box className="contents-header">
        <Box className="inner">
          <Typography className="category" variant="caption">
            Contact Us
          </Typography>
          <Typography className="tit" variant="h3">
            문의하기
          </Typography>
        </Box>
      </Box>
      <Box className="contents-container">
        <Box className="inner">
          <Typography className="desc" variant="body1">
            <strong>에임블에 궁금하신 내용이 있으신가요?</strong>
            <small>
              궁금하신 사항을 남겨주시면 해당 부서에서 신속하게
              답변해드리겠습니다.
            </small>
          </Typography>
          <Typography className="email" variant="body1">
            <MailOutlineIcon fontSize="large" />
            <strong>담당자 이메일</strong>
            <span>hssung@aimble.com</span>
          </Typography>
          <Box className="inquiry-list">
            <Card>
              <CardContent className="bg-company">
                <Typography variant="h5" className="tit">
                  기업정보
                </Typography>
                <Typography variant="body2" className="desc">
                  에임블 기업정보 문의
                </Typography>
              </CardContent>
            </Card>
            <Card>
              <CardContent className="bg-technology">
                <Typography variant="h5" className="tit">
                  연구개발
                </Typography>
                <Typography variant="body2" className="desc">
                  신약 개발 관련 문의
                </Typography>
              </CardContent>
            </Card>
            <Card>
              <CardContent className="bg-partnership">
                <Typography variant="h5" className="tit">
                  제휴/협력
                </Typography>
                <Typography variant="body2" className="desc">
                  합작투자, 협력개발 문의
                </Typography>
              </CardContent>
            </Card>
            <Card>
              <CardContent className="bg-recruit">
                <Typography variant="h5" className="tit">
                  채용
                </Typography>
                <Typography variant="body2" className="desc">
                  입사지원 문의
                </Typography>
              </CardContent>
            </Card>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
export default ContactUs;
