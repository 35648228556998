import React from "react";
import { Box, Typography } from "@mui/material";
import imgCADDStep1 from "../img/img_cadd_step_01.svg";
import imgCADDStep2 from "../img/img_cadd_step_02.svg";
import imgCADDStep3 from "../img/img_cadd_step_03.svg";
import imgCADDStep4 from "../img/img_cadd_step_04.svg";
import imgCADDStep5 from "../img/img_cadd_step_05.svg";

function Service() {
  return (
    <Box className="contents business">
      <Box className="contents-header">
        <Box className="inner">
          <Typography className="category" variant="caption">
            Business
          </Typography>
          <Typography className="tit" variant="h3">
            Service
          </Typography>
        </Box>
      </Box>
      <Box className="contents-container service">
        <Box className="inner">
          <Typography className="tit" variant="h3">
            Drug Design & Synthesis
          </Typography>
          <div className="article-wrap">
            <Box className="article">
              <img className="img" src={imgCADDStep1} alt="CADD Step1" />
              <dl className="service-steps">
                <dt>구조 분석 및 스크리닝</dt>
                <dd>Identification of binding modes in protein</dd>
                <dd>Virtual Screening with AIMBLE chemical library</dd>
                <dd>AI/MD-based screening</dd>
              </dl>
            </Box>
            <Box className="article">
              <img className="img" src={imgCADDStep2} alt="CADD Step2" />
              <dl className="service-steps">
                <dt>Hit 물질 발굴</dt>
                <dd>Structural Analysis of protein-ligand complex</dd>
                <dd>Drug Design &#38; Hit Identification</dd>
              </dl>
            </Box>
            <Box className="article">
              <img className="img" src={imgCADDStep3} alt="CADD Step3" />
              <dl className="service-steps">
                <dt>화합물 합성 및 약리실험</dt>
                <dd>Chemical Synthesis</dd>
                <dd>In vitro experiments</dd>
              </dl>
            </Box>
            <Box className="article">
              <img className="img" src={imgCADDStep4} alt="CADD Step4" />
              <dl className="service-steps">
                <dt>SAR을 통한 Lead 물질 발굴</dt>
                <dd>SAR Analysis</dd>
                <dd>Lead Identification</dd>
              </dl>
            </Box>
            <Box className="article">
              <img className="img" src={imgCADDStep5} alt="CADD Step5" />
              <dl className="service-steps">
                <dt>특허 출원 및 Data 확립</dt>
                <dd>Patent submission</dd>
                <dd>Data establishment</dd>
              </dl>
            </Box>
          </div>
        </Box>
      </Box>
    </Box>
  );
}
export default Service;
