import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { Box } from "@mui/material";
import partners1 from "../img/partners_01.png";
import partners2 from "../img/partners_02.png";
import partners3 from "../img/partners_03.png";
import partners4 from "../img/partners_04.png";
import partners5 from "../img/partners_05.png";
import partners6 from "../img/partners_06.png";
import partners7 from "../img/partners_07.png";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const itemData = [
  {
    id: 1,
    imgPath: partners1,
    label: "DAEWONPHARM",
  },
  {
    id: 2,
    imgPath: partners2,
    label: "EPINOGEN",
  },
  {
    id: 3,
    imgPath: partners3,
    label: "VAXDIGM",
  },
  {
    id: 4,
    imgPath: partners4,
    label: "KSB TUGEN",
  },
  {
    id: 5,
    imgPath: partners5,
    label: "VAXDIGM",
  },
  {
    id: 6,
    imgPath: partners6,
    label: "KSB TUGEN",
  },
  {
    id: 7,
    imgPath: partners7,
    label: "KSB TUGEN",
  },
];

function SwiperPartners() {
  return (
    <>
      <Swiper
        slidesPerView={4}
        spaceBetween={10}
        centeredSlides={true}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        loop
        className="swiper-partners"
        breakpoints={{
          320: {
            slidesPerView: 1.5,
            spaceBetween: 10,
          },
          450: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          640: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          980: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 3.5,
            spaceBetween: 20,
          },
          1440: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
        }}
      >
        {itemData.map((item) => (
          <SwiperSlide key={item.id}>
            <Box
              component="img"
              className="partners-img"
              src={item.imgPath}
              alt={item.label}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
export default SwiperPartners;
