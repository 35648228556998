import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Box } from "@mui/material";
import Footer from "./Footer";
import Home from "../pages/Home";
import AboutUs from "../pages/AboutUs";
import Team from "../pages/Team";
import History from "../pages/History";
import Platform from "../pages/Platform";
import Service from "../pages/Service";
import Notice from "../pages/Notice";
import PressRelease from "../pages/PressRelease";
import PressReleaseDetail from "../pages/PressReleaseDetail";
import ContactUs from "../pages/ContactUs";
import Location from "../pages/Location";
import NoticeDetail from "../pages/NoticeDetail";

export default function ContainerFluid() {
  return (
    <Box className="container-fluid">
      <Box component="main" className="container">
        <Routes>
          <Route path="/Home" element={<Home />} />
          <Route path="/AboutUs" element={<AboutUs />} />
          <Route path="/Team" element={<Team />} />
          <Route path="/History" element={<History />} />
          <Route path="/Platform" element={<Platform />} />
          <Route path="/Service" element={<Service />} />
          <Route path="/Notice" element={<Notice />} />
          <Route path="/NoticeDetail" element={<NoticeDetail />} />
          <Route path="/PressRelease" element={<PressRelease />} />
          <Route path="/PressReleaseDetail" element={<PressReleaseDetail />} />
          <Route path="/ContactUs" element={<ContactUs />} />
          <Route path="/Location" element={<Location />} />
          <Route path="/*" element={<Navigate replace to="/Home" />} />
        </Routes>
      </Box>
      <Footer />
    </Box>
  );
}
