import React from "react";
import { Box, Typography } from "@mui/material";

function Location() {
  return (
    <Box className="contents contact-us">
      <Box className="contents-header">
        <Box className="inner">
          <Typography className="category" variant="caption">
            Contact Us
          </Typography>
          <Typography className="tit" variant="h3">
            오시는 길
          </Typography>
        </Box>
      </Box>
      <Box className="contents-container">
        <Box className="inner">
          <dl className="address-list">
            <dt>CADD 연구소</dt>
            <dd>서울특별시 성동구 상원1길 26 서울숲 A타워 1107호</dd>
            <dd className="iframe-wrap">
              <iframe
                title="location1"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3163.3785857917705!2d127.04603387673217!3d37.54614332529568!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca5a66fe13d65%3A0x4ba294f08803e61c!2z7ISc7Jq47IiyQe2DgOybjA!5e0!3m2!1sko!2skr!4v1716855831078!5m2!1sko!2skr"
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </dd>
            <dt>합성 연구소</dt>
            <dd>경기도 수원시 영통구 창룡대로 260 광교 센트럴비즈타워 625호</dd>
            <dd className="iframe-wrap">
              <iframe
                title="location2"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1166.2309809187318!2d127.03662220567108!3d37.295350560734846!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357b5c9f81eb7b69%3A0x98087c8befc80286!2z6rK96riw64-EIOyImOybkOyLnCDsmIHthrXqtawg7LC966Oh64yA66GcIDI2MA!5e0!3m2!1sko!2skr!4v1716855969491!5m2!1sko!2skr"
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </dd>
          </dl>
        </Box>
      </Box>
    </Box>
  );
}
export default Location;
