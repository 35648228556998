import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./scss/index.scss";
import App from "./App";
import {
  QueryClient,
  QueryClientProvider,
  QueryCache,
  MutationCache,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { errorHandler } from "./utils/errorHandler";

const root = ReactDOM.createRoot(document.getElementById("root"));

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error, query) => {
      errorHandler(error, query);
    },
  }),
  mutationCache: new MutationCache({
    onError: (error, query) => {
      errorHandler(error, query);
    },
  }),
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
    },
    mutations: {
      retry: 0,
    },
  },
});

root.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <App />
      {process.env.REACT_APP_ENV !== "production" && (
        <ReactQueryDevtools initialIsOpen={false} />
      )}
    </QueryClientProvider>
  </BrowserRouter>
);
