import React from "react";
import { Box, Typography, Button } from "@mui/material";
import MainCarousel from "../components/MainCarousel";
import EastIcon from "@mui/icons-material/East";
import imgCADD from "../img/img_cadd.svg";
import SwiperNewsroom from "../components/SwiperNewsroom";
import SwiperPartners from "../components/SwiperPartners";

function Home() {
  return (
    <Box className="home">
      <MainCarousel />
      <Box className="home-technology">
        <Box className="home-technology-header">
          <Typography className="headline" variant="h3">
            <span className="tit">Business</span>
            <span className="desc">에임블의 약물 설계 프로세스</span>
          </Typography>
          <Button
            className="shortcut"
            color="inherit"
            variant="outlined"
            endIcon={<EastIcon />}
            href="/Service"
          >
            View more
          </Button>
        </Box>
        <Box className="home-technology-container">
          <img className="img" src={imgCADD} alt="CADD" />
        </Box>
      </Box>
      <Box className="home-news">
        <Box className="home-news-header">
          <Typography className="headline" variant="h3">
            <span className="tit">Newsroom</span>
            <span className="desc">
              공지사항 및 <br />
              최신 보도자료
            </span>
          </Typography>
          <Button
            className="shortcut"
            color="inherit"
            variant="outlined"
            endIcon={<EastIcon />}
            href="/Notice"
          >
            View more
          </Button>
        </Box>
        <Box className="home-news-container">
          <SwiperNewsroom />
        </Box>
      </Box>

      <Box className="home-partners">
        <Typography className="headline" variant="h3">
          <span className="tit">Partnership</span>
          <span className="desc xl">
            파트너사와 함께 어제보다 나은 오늘을 만들어 갑니다.
          </span>
        </Typography>
        <Box className="partners">
          <SwiperPartners />
        </Box>
      </Box>

      <Box className="home-contact">
        <Typography className="headline" variant="h3">
          <span className="tit">Contact Us</span>
          <span className="desc lg">
            에임블에 궁금하신 내용이 있으신가요? <br />
            기술 관련 및 기타 궁금하신 점을 문의해보세요.
          </span>
        </Typography>
        <Button
          className="shortcut"
          color="white"
          variant="outlined"
          endIcon={<EastIcon />}
          href="/ContactUs"
        >
          문의하기
        </Button>
      </Box>
    </Box>
  );
}
export default Home;
