import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { Box, Typography, Chip, Button } from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import "swiper/css";
import "swiper/css/navigation";
import { useNewestBoardList } from "../hook/useNewestBoardList";

function SwiperNewsroom() {
  const { data: boardList, isSuccess } = useNewestBoardList();

  return (
    <>
      <Swiper
        slidesPerView={3}
        centeredSlides={true}
        spaceBetween={30}
        navigation={true}
        modules={[Navigation]}
        className="swiper-newsroom"
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 1.5,
            spaceBetween: 20,
          },
          980: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1440: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          1920: {
            slidesPerView: 3.5,
            spaceBetween: 30,
          },
        }}
        // autoHeight={true}
      >
        {isSuccess &&
          boardList &&
          boardList.list.map((row) => {
            return (
              <SwiperSlide key={row.board_id}>
                <Box className="txt-wrap">
                  {row.type === "notice" && (
                    <Chip
                      label="Notice"
                      variant="contained"
                      color="secondary"
                    />
                  )}
                  {row.type === "press" && (
                    <Chip
                      label="Press Release"
                      variant="contained"
                      color="primary"
                    />
                  )}

                  <Typography className="tit" variant="h5">
                    {row.title}
                  </Typography>
                  <Typography className="desc" variant="body2">
                    {row.content.replace(/(<([^>]+)>)/gi, "")}
                  </Typography>
                  <span className="date">{row.ins_dt}</span>
                </Box>
                <Button
                  variant="text"
                  color="inherit"
                  className="btn"
                  href={
                    row.type === "notice"
                      ? "/NoticeDetail?board_id=" + row.board_id
                      : "/PressReleaseDetail?board_id=" + row.board_id
                  }
                >
                  <span>View more</span>
                  <EastIcon />
                </Button>
              </SwiperSlide>
            );
          })}
      </Swiper>
    </>
  );
}
export default SwiperNewsroom;
