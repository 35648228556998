import React from "react";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import footerLogo from "../img/footerlogo.svg";

export default function Footer() {
  return (
    <Box className="footer" component="footer">
      <Box className="footer-info">
        <img
          className="footer-logo"
          src={footerLogo}
          alt="Aimble footer Logo"
        />
        <dl className="footer-info-company">
          <dt>회사명</dt>
          <dd>주식회사 에임블</dd>
          <dt>사업자등록번호</dt>
          <dd>462-87-01590</dd>
          <dt>대표자</dt>
          <dd>김현진</dd>
          <dt>이메일</dt>
          <dd>hssung@aimble.com</dd>
        </dl>
        <dl className="footer-info-address">
          <dt>CADD 연구소</dt>
          <dd>서울특별시 성동구 상원1길 26 서울숲 A타워 1107호</dd>
        </dl>
        <Typography className="footer-info-copy">
          {"Copyright © " +
            new Date().getFullYear() +
            " Aimble. All rights reserved."}
        </Typography>
      </Box>
      <Box className="footer-nav">
        <List className="main-menu">
          <ListItem>
            <ListItemButton className="menu-levels-1">
              <ListItemText primary="Company" />
            </ListItemButton>
            <List className="sub-menu">
              <ListItemButton className="menu-levels-2" href="/AboutUs">
                <ListItemText primary="회사소개" />
              </ListItemButton>
              <ListItemButton className="menu-levels-2" href="/Team">
                <ListItemText primary="구성원" />
              </ListItemButton>
              <ListItemButton className="menu-levels-2" href="/History">
                <ListItemText primary="연혁" />
              </ListItemButton>
            </List>
          </ListItem>
          <ListItem>
            <ListItemButton className="menu-levels-1">
              <ListItemText primary="Business" />
            </ListItemButton>
            <List className="sub-menu">
              <ListItemButton className="menu-levels-2" href="/Platform">
                <ListItemText primary="Platform" />
              </ListItemButton>
              <ListItemButton className="menu-levels-2" href="/Service">
                <ListItemText primary="Service" />
              </ListItemButton>
            </List>
          </ListItem>
          <ListItem>
            <ListItemButton className="menu-levels-1">
              <ListItemText primary="Newsroom" />
            </ListItemButton>
            <List className="sub-menu">
              <ListItemButton className="menu-levels-2" href="/Notice">
                <ListItemText primary="Notice" />
              </ListItemButton>
              <ListItemButton className="menu-levels-2" href="/PressRelease">
                <ListItemText primary="Press Release" />
              </ListItemButton>
            </List>
          </ListItem>
          <ListItem>
            <ListItemButton className="menu-levels-1">
              <ListItemText primary="Contact Us" />
            </ListItemButton>
            <List className="sub-menu">
              <ListItemButton className="menu-levels-2" href="/ContactUs">
                <ListItemText primary="문의하기" />
              </ListItemButton>
              <ListItemButton className="menu-levels-2" href="/Location">
                <ListItemText primary="오시는 길" />
              </ListItemButton>
            </List>
          </ListItem>
        </List>
      </Box>
    </Box>
  );
}
