import React from "react";
import { Box, Typography } from "@mui/material";
import mainImg1 from "../img/carousel_main_01.jpg";
import mainImg2 from "../img/carousel_main_02.jpg";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const images = [
  {
    key: 1,
    imgPath: mainImg1,
    imgTitle: "AI기반 신약 후보물질 발굴 플랫폼",
    imgSubTitle: "CADD, Computer-Aided Drug Design",
    imgDescription:
      "AIMBLE Structure-Based Drug Design Platform is generated by integrating high technologies of docking, AI, quantum calculation and molecular dynamics simulation.",
  },
  {
    key: 2,
    imgPath: mainImg2,
    imgTitle: "에임블 합성(Synthesis)",
    imgSubTitle: "",
    imgDescription:
      "For speedy in-vitro and in-vivo experiments, AIMBLE Synthesis Lab directly constructs chemical compounds designed via the AIMBLE CADD Platform.",
  },
];

function MainCarousel() {
  return (
    <Box className="main-carousel">
      <Swiper
        centeredSlides={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        style={{
          "--swiper-pagination-color": "rgba(255, 255, 255, .75)",
          "--swiper-navigation-color": "rgba(255, 255, 255, .75)",
        }}
      >
        {images.map((step) => (
          <SwiperSlide key={step.key}>
            <img className="carousel-img" src={step.imgPath} alt={step.label} />
            <Typography className="carousel-txt">
              <span className="tit">{step.imgTitle}</span>
              <span className="subtit">{step.imgSubTitle}</span>
              <span className="desc">{step.imgDescription}</span>
            </Typography>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
}

export default MainCarousel;
